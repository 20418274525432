/* eslint-disable react-hooks/exhaustive-deps */
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

interface IChecklist {
  [prop: string]: {
    checked?: boolean;
    text: string;
  };
}

const progressList: string[] = [
  "#E20F1A",
  "#E20F1A",
  "#E20F1A",
  "#EF9A04",
  "#EF9A04",
  "#EF9A04",
  "#1AB83F",
  "#1AB83F",
  "#1AB83F",
];

const checkList: IChecklist = {
  size: {
    text: "8 caracteres",
  },
  lowercase: {
    text: "Caixa baixa",
  },
  uppercase: {
    text: "Caixa alta",
  },
  numbers: {
    text: "Números",
  },
  symbols: {
    text: "Símbolos",
  },
};
interface PasswordCheckerProps {
  value: string;
  onChange?: (isValid: boolean) => void;
}

const PasswordChecker: React.FC<PasswordCheckerProps> = ({
  value,
  onChange,
}) => {
  const [authCheckList, setAuthCheckList] = useState<IChecklist>(checkList);

  const ProgressItem: React.FC<{ background: string | number }> = ({
    background,
  }) => {
    return (
      <div
        style={{
          height: "0.5rem",
          width: "9%",
          background: background,
          margin: "10 0",
        }}
      />
    );
  };

  const CheckItem: React.FC<{ checked: boolean; text: string }> = ({
    checked,
    text,
  }) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        {checked ? (
          <Check sx={{ color: "#808080", fontSize: "1.5rem" }} />
        ) : (
          <CloseIcon sx={{ color: "#DE101B", fontSize: "1.5rem" }} />
        )}
        <Typography fontSize={"1rem"} color="#808080">
          {text}
        </Typography>
      </Box>
    );
  };

  const passwordCheck = useCallback(() => {
    let newAuthCheckList: IChecklist = {
      ...authCheckList,
      size: {
        ...authCheckList["size"],
        checked: value.length >= 8,
      },
    };

    newAuthCheckList = {
      ...newAuthCheckList,
      lowercase: {
        ...newAuthCheckList["lowercase"],
        checked: /[a-z]/.test(value),
      },
    };

    newAuthCheckList = {
      ...newAuthCheckList,
      uppercase: {
        ...newAuthCheckList["uppercase"],
        checked: /[A-Z]/.test(value),
      },
    };
    newAuthCheckList = {
      ...newAuthCheckList,
      numbers: {
        ...newAuthCheckList["numbers"],
        checked: /\d/.test(value),
      },
    };
    newAuthCheckList = {
      ...newAuthCheckList,
      symbols: {
        ...newAuthCheckList["symbols"],
        checked: /[!@#$%^&*]/.test(value),
      },
    };

    setAuthCheckList(newAuthCheckList);
  }, [authCheckList, value]);
  useEffect(() => {
    passwordCheck();
  }, [value]);

  useEffect(() => {
    if (onChange) {
      const isValid = Object.keys(authCheckList).every(
        (key) => authCheckList[key].checked
      );
      onChange(isValid);
    }
  }, [authCheckList, onChange]);

  let checkedTotal = Object.keys(authCheckList).filter(
    (key) => authCheckList[key].checked
  ).length;
  checkedTotal += checkedTotal - 1;

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} mt={1.5}>
        {progressList.map((color, index) => (
          <ProgressItem
            key={index}
            background={index <= checkedTotal ? color : "#D9D9D9"}
          />
        ))}
      </Box>
      <Box
        display={{ xs: "block", md: "flex" }}
        justifyContent={"space-between"}
        mt={1.5}
      >
        <Box mb={{ xs: 1, md: 0 }}>
          <Typography fontSize={"1rem"} color="#808080">
            {authCheckList["size"].text}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          sx={{
            "& div": {
              margin: "0 0.5rem",
            },
            "& div:last-child": {
              marginRight: 0,
            },
            "& div:first-child": {
              marginLeft: 0,
            },
          }}
        >
          {Object.keys(authCheckList)
            .slice(1)
            .map((key) => (
              <CheckItem
                key={key}
                checked={authCheckList[key].checked}
                text={authCheckList[key].text}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordChecker;
