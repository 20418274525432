import { Box, Grid } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { StyledGridContainer } from "../../components/StyledLayout";
// import UserCard from "../../components/UserCard";
import { routesConfig } from "../../config/routes";
import { useIocContext } from "../../contexts/ioc/IocContext";
import {
  useContextualNavigate,
  useNavigationCookies,
} from "../../hooks/ContextualNavigations";
import { Types } from "../../ioc/types";
import {
  IAuthService,
  IAuthUpdatePasswordDTO,
} from "../../modules/user/models/IAuthService";
import AppError from "../../utils/appError";
import { getSnackbar } from "../../utils/Snackbars";
import CreateNewPasswordCard from "./CreateNewPasswordCard";
import { useFlags } from "flagsmith/react";

export const CreateNewPassword: React.FC = () => {
  let navigate = useContextualNavigate();
  let navigateCookies = useNavigationCookies();

  const flags = useFlags(["new_authentication_flow"]);
  const new_authentication_flow = flags.new_authentication_flow.enabled;

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const location = useLocation();
  if (location.state) {
    var { email, old_password } = location.state as IAuthUpdatePasswordDTO;
  }

  const urlString = window.location.href;
  const paramString = urlString.split("?")[1];
  const queryString = new URLSearchParams(paramString);
  const isPasswordRecovery: boolean =
    Boolean(queryString.get("verification_code")) &&
    Boolean(queryString.get("email"));

  const { enqueueSnackbar } = useSnackbar();

  const navigateDom = useNavigate();

  const validationSchema = yup.object().shape({
    new_password: yup.string().required("Este campo é obrigatório"),
    confirm_password: yup.string().required("Este campo é obrigatório"),
  });

  const handleSubmit = async (
    values: IAuthUpdatePasswordDTO,
    actions: FormikHelpers<IAuthUpdatePasswordDTO>
  ) => {
    try {
      const redirectURL = queryString.get("url");

      if (!new_authentication_flow) {
        if (urlString.indexOf("?") === -1) {
          throw new Error("Ausencia de Parametros na Url");
        }

        if (!queryString.get("url")) {
          throw new Error(
            "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
          );
        }

        if (!queryString.get("rules")) {
          throw new Error(
            "Ausencia de Parametros na Url - regras do projeto (rules off) "
          );
        }
      }

      if (isPasswordRecovery) {
        await authService.changePassword({
          email: queryString.get("email"),
          password: values.confirm_password,
          confirmation_code: queryString.get("verification_code"),
        });

        if (!new_authentication_flow) {
          const urls = [];
          const rules = queryString.get("rules");
          queryString.forEach(
            (ele, idx) =>
              idx === "url" && urls.push(ele) && queryString.delete(ele)
          );

          const query = `url=${urls[1]}&rules=${rules}`;
          navigate(`${routesConfig.LOGIN}?${query}`, { replace: true });
        } else {
          navigateDom(routesConfig.LOGIN);
        }
      } else {
        const loginResponse = await authService.updatePassword(values);
        if (!new_authentication_flow) {
          navigateCookies({
            accessToken: loginResponse.AccessToken,
            refreshToken: loginResponse.RefreshToken,
            redirectURL,
          });
        } else {
          navigateDom(routesConfig.LOGIN);
        }
      }
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message: error.message,
            variant: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!isPasswordRecovery && (!location.state || !email || !old_password)) {
      navigate(routesConfig.LOGIN);
    }
  }, [email, isPasswordRecovery, location.state, navigate, old_password]);

  return (
    <Formik
      initialValues={{
        email,
        old_password,
        new_password: "",
        confirm_password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <Grid
              item
              xs={12}
              md={7}
              display={{ xs: "none", md: "flex" }}
              flexDirection={"column"}
            >
              <Box sx={{ height: { xs: 0, md: "52%" } }} />
              <Box sx={{ height: { xs: 0, md: "48%" } }}>
                <Grid container spacing={3} ml={"5%"} mt={3}>
                  {/*<Grid item xs={12}>*/}
                  {/*    <Typography color='white'>*/}
                  {/*        Entrar como*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*<Grid item>*/}
                  {/*    <UserCard*/}
                  {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                  {/*        name={'John Peter'}*/}
                  {/*        status={'Ativo há 1 dia atrás'}*/}
                  {/*    />*/}
                  {/*</Grid>*/}
                  {/*<Grid item>*/}
                  {/*    <UserCard*/}
                  {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                  {/*        name={'John Peter'}*/}
                  {/*        status={'Ativo há 1 dia atrás'}*/}
                  {/*    />*/}
                  {/*</Grid>*/}
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              mt={{ xs: 6, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CreateNewPasswordCard formikProps={props} />
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNewPassword;
