import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "notistack";

import { RoutesComponent } from "./components/Routes";
import { AtemTheme } from "./components/Theme";
import { IocProvider } from "./contexts/ioc/IocContext";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { isDevelopment } from "./config/default";

function App() {
  return (
    <FlagsmithProvider
      options={{
        environmentID: isDevelopment
          ? "MrLvMaf4PB5kpq4E9yqTK2"
          : "eLYm4fhXXL6JfNb9daHPC8",
        api: "https://flagsmith.ream.com.br/api/v1/",
      }}
      flagsmith={flagsmith}
    >
      <IocProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          content={(key, message) => message}
        >
          <ThemeProvider theme={AtemTheme()}>
            <CssBaseline />
            <RoutesComponent />
          </ThemeProvider>
        </SnackbarProvider>
      </IocProvider>
    </FlagsmithProvider>
  );
}

export default App;
