export interface TokenDecoded {
    sub: string;
    auth_time: number;
    exp: number;
    iat: number;
    username: string;
}

export interface IAuthLoginDTO {
    email: string
    password: string
    mfaToken?: string
}

export enum AuthState {
    SENT = 'SENT',
    FAILED_SENT = 'FAILED_SENT',
    EXHAUSTED_ATTEMPTS = 'EXHAUSTED_ATTEMPTS',
    MUST_START_CHALLENGE = 'MUST_START_CHALLENGE',
    INCORRECT_TOKEN = 'INCORRECT_TOKEN',
    TOO_MANY_ACCESSES = 'TOO_MANY_ACCESSES',
    FIRST_ACCESS = 'FIRST_ACCESS',
}
export interface IAuthLoginResponse {
    state: AuthState
    AccessToken: string;
    ExpiresIn: number;
    RefreshToken: string
    isLoginyQrCode: boolean;
    meta?: {
        email: string;
        name: string;
        custom_attributes: {
            userStatus: string;
        };
        groups: string[];
        roles: string[];
    }
}
export interface IAuthForgetPasswordDTO {
    email: string;
    metadata?: IMetadataDTO;
}

export interface IMetadataDTO {
    url: string;
    rules: string;
}

export interface IAuthChangePasswordDTO {
    email: string;
    password: string;
    confirmation_code: string;
}

export interface RegisterUserService {
    name: string;
    email: string;
    password: string;
    role: string;
}

export interface IAuthUpdatePasswordDTO {
    email: string;
    old_password: string;
    new_password: string;
    confirm_password: string;
}
export interface IAuthService {
    authLogin(data: IAuthLoginDTO): Promise<IAuthLoginResponse>;

    updatePassword(data: IAuthUpdatePasswordDTO): Promise<IAuthLoginResponse>;
    changePassword(data: IAuthChangePasswordDTO): Promise<void>;

    forgetPassword(data: IAuthForgetPasswordDTO): Promise<void>;
    register(data: RegisterUserService): Promise<void>;
}
