export const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT === "development" ||
  window.location.hostname === "localhost" ||
  Boolean(window.location.origin.split(".")[0].match(/-dev$/));

export function generateDevUrl(path: string) {
  return isDevelopment ? `${path}-dev` : path;
}

const config = {
  api: {
    urlLocal: "http://localhost:8080",
    url: `https://${generateDevUrl("mw")}.ream.com.br/mw-auth`,
    timeout: 30000,
  },
  auth: { keys: { token: "@ATEM:grupoAtem:tokenGrupoAtem" } },
  domain: (window.location.hostname === "localhost"
    ? "localhost"
    : `.${window.location.hostname.replace(/(.*\.)?([\w|-]+.com.*)/, "$2")}`
  ).replace(/\.+/g, "."),
  CONFIRMED: "CONFIRMED",
};

export default config;
