import { generateDevUrl } from "../config/default";

export const generateUrlToRedirect = (system: string, port: number): string => {
  const url = window.location.hostname;
  const isLocal = Boolean(url.match(/localhost/));

  if (isLocal) {
    return `http://${url}:${port}`;
  }

  return `https://${generateDevUrl(system)}.ream.com.br`;
};
