import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { StyledGridContainer } from "../../components/StyledLayout";
// import UserCard from "../../components/UserCard";
// import { useContextualNavigate } from "../../hooks/ContextualNavigations";
import AppError from "../../utils/appError";
import { getSnackbar } from "../../utils/Snackbars";
import RequestAccessCard from "./RequestAccessCard";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RequestAccess: React.FC = () => {
    // let navigate = useContextualNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Insira um e-mail válido")
            .required("Este campo é obrigatório"),
        name: yup.string().required("Este campo é obrigatório"),
        phone: yup.string().matches(phoneRegExp, "Telefone inválido"),
        companyName: yup.string().required("Este campo é obrigatório"),
    });

    return (
        <Formik
            initialValues={{ email: "", name: "", phone: "", companyName: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    const urlString = window.location.href;
                    if (urlString.indexOf("?") === -1) { throw new Error("Ausencia de Parametros na Url"); }

                    const paramString = urlString.split('?')[1];

                    const queryString = new URLSearchParams(paramString);

                    if (!queryString.get('url')) { throw new Error("Ausencia de Parametros na Url - link de Redirecionamento (url off)") };
                    // const redirectURL = queryString.get('url');

                    if (!queryString.get('rules')) { throw new Error("Ausencia de Parametros na Url - regras do projeto (rules off) ") };
                    // const rules = queryString.get('rules');
                } catch (error) {
                    if (error as AppError) {
                        enqueueSnackbar(
                            getSnackbar({
                                message: error.message,
                                variant: "error",
                            })
                        );
                    }
                }
            }}
        >
            {(props) => (
                <Form>
                    <StyledGridContainer>
                        <Grid item xs={12} md={7} display={{ xs: 'none', md: 'flex' }} flexDirection={'column'}>
                            <Box sx={{ height: { xs: 0, md: '52%' } }} />
                            <Box sx={{ height: { xs: 0, md: '48%' } }}>
                                <Grid container spacing={3} ml={'5%'} mt={3}>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Typography color='white'>*/}
                                    {/*        Entrar como*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item>*/}
                                    {/*    <UserCard*/}
                                    {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                                    {/*        name={'John Peter'}*/}
                                    {/*        status={'Ativo há 1 dia atrás'}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item>*/}
                                    {/*    <UserCard*/}
                                    {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                                    {/*        name={'John Peter'}*/}
                                    {/*        status={'Ativo há 1 dia atrás'}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={5} mt={{ xs: 6, md: 0 }}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <RequestAccessCard formikProps={props} />
                        </Grid>
                    </StyledGridContainer>
                </Form>
            )}
        </Formik>
    );
}

export default RequestAccess;
