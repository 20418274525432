import React from "react";
import { SvgIcon, SvgIconProps } from '@mui/material';

import { ReactComponent as noVisibleIMG } from "../../assets/novisible.svg";
import { ReactComponent as vibileIMG } from "../../assets/visible.svg"

export const NoVisibleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={noVisibleIMG} viewBox="0 0 16 16" {...props} />
  );
};

export const VisibleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={vibileIMG} viewBox="0 0 16 16" {...props}></SvgIcon>
  );
}