import { NavigateOptions } from "react-router";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import config from "../config/default";

export const useContextualNavigate = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  const query = location.split("?")[1];

  return (to: string, options?: NavigateOptions) => {
    const queryParams = to.includes("?") ? `&${query}` : `?${query}`;
    return navigate(`${to}${query ? queryParams : ""}`, options);
  };
};

export const useQueryParams = () => {
  const location = window.location.href;
  const params = location.split("?")[1];
  const searchParams = Object.fromEntries(
    new URLSearchParams(params).entries()
  );
  const { url: raw_url, rules, email, verification_code } = searchParams;
  const url = raw_url && new URL(raw_url).origin;
  const query = { url, rules, email, verification_code };

  Object.keys(query).forEach((key) => (!query[key] ? delete query[key] : {}));
  const raw_query = Object.entries(query)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  return {
    query: `${raw_query ? `?${raw_query}` : ""}`,
    queryDict: searchParams,
  };
};

interface INavigationCookiesOptions {
  accessToken: string;
  refreshToken: string;
  redirectURL: string;
}
export const useNavigationCookies = () => {
  return (navigate: INavigationCookiesOptions) => {
    const cookies = new Cookies();
    cookies.set("accessToken", navigate.accessToken, {
      path: "/",
      domain: config.domain,
    });
    cookies.set("refreshToken", navigate.refreshToken, {
      path: "/",
      domain: config.domain,
    });

    document.location.href = navigate.redirectURL;
  };
};

export const isHomologationMode = () => {
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];
  const testDomainList = ["localhost", "teste", "dev"];
  const environment = process.env.REACT_APP_ENVIRONMENT;

  return (
    environment === "homologation" ||
    testDomainList.some((item) => subdomain.includes(item))
  );
};
