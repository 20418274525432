import { Box, Grid } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { StyledGridContainer } from "../../components/StyledLayout";
// import UserCard from "../../components/UserCard";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { Types } from "../../ioc/types";
import {
  IAuthLoginDTO,
  IAuthService,
} from "../../modules/user/models/IAuthService";
import AppError from "../../utils/appError";
import { getSnackbar } from "../../utils/Snackbars";
import PasswordRecoveryCard from "./PasswordRecoveryCard";
import { useFlags } from "flagsmith/react";

export const PasswordRecovery: React.FC = () => {
  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();
  const flags = useFlags(["new_authentication_flow"]);
  const new_authentication_flow = flags.new_authentication_flow.enabled;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
  });

  const handleSubmit = async (
    values: IAuthLoginDTO,
    actions: FormikHelpers<IAuthLoginDTO>
  ) => {
    try {
      const urlString = window.location.href;
      const paramString = urlString.split("?")[1];
      const queryString = new URLSearchParams(paramString);

      if (!new_authentication_flow) {
        if (urlString.indexOf("?") === -1) {
          throw new Error("Ausencia de Parametros na Url");
        }

        if (!queryString.get("url")) {
          throw new Error(
            "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
          );
        }

        if (!queryString.get("rules")) {
          throw new Error(
            "Ausencia de Parametros na Url - regras do projeto (rules off) "
          );
        }
      }

      await authService.forgetPassword(
        new_authentication_flow
          ? { ...values }
          : {
              ...values,
              metadata: {
                url: queryString.get("url"),
                rules: queryString.get("rules"),
              },
            }
      );

      enqueueSnackbar(
        getSnackbar({
          message:
            "Um link de recuperação foi enviado para o seu email. Por favor, verifique sua caixa de entrada para continuar",
          variant: "success",
        })
      );
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message:
              error.message ?? "Erro enviar link de recuperação de senha",
            variant: "error",
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "", mfaToken: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <Grid
              item
              xs={12}
              md={7}
              display={{ xs: "none", md: "flex" }}
              flexDirection={"column"}
            >
              <Box sx={{ height: { xs: 0, md: "52%" } }} />
              <Box sx={{ height: { xs: 0, md: "48%" } }}>
                <Grid container spacing={3} ml={"5%"} mt={3}>
                  {/*<Grid item xs={12}>*/}
                  {/*    <Typography color='white'>*/}
                  {/*        Entrar como*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*<Grid item>*/}
                  {/*    <UserCard*/}
                  {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                  {/*        name={'John Peter'}*/}
                  {/*        status={'Ativo há 1 dia atrás'}*/}
                  {/*    />*/}
                  {/*</Grid>*/}
                  {/*<Grid item>*/}
                  {/*    <UserCard*/}
                  {/*        avatar={() => <Avatar src='images/avatar.png' sx={{ width: '7.6rem', height: '7.6rem' }} />}*/}
                  {/*        name={'John Peter'}*/}
                  {/*        status={'Ativo há 1 dia atrás'}*/}
                  {/*    />*/}
                  {/*</Grid>*/}
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              mt={{ xs: 6, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PasswordRecoveryCard formikProps={props} />
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRecovery;
