import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { StyledGridContainer } from "../../components/StyledLayout";
import ROLES from "../../config/roles";
import { routesConfig } from "../../config/routes";
import { useIocContext } from "../../contexts/ioc/IocContext";
import {
  useContextualNavigate,
  useNavigationCookies,
} from "../../hooks/ContextualNavigations";
import { Types } from "../../ioc/types";
import {
  AuthState,
  IAuthLoginDTO,
  IAuthService,
} from "../../modules/user/models/IAuthService";
import { getSnackbar } from "../../utils/Snackbars";
import AppError from "../../utils/appError";
import SecurityAuthCard from "./SecurityAuthCard";
import { useFlags } from "flagsmith/react";
import { generateUrlToRedirect } from "../../utils";
import { Port } from "../Login/localPort";

export const SecurityAuth: React.FC = () => {
  let navigate = useContextualNavigate();
  let navigateCookies = useNavigationCookies();

  const location = useLocation();

  const flags = useFlags(["new_authentication_flow"]);
  const new_authentication_flow = flags.new_authentication_flow.enabled;

  if (location.state) {
    var { email, password } = location.state as IAuthLoginDTO;
  }

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object().shape({
    mfaToken: yup.number().required("Este campo é obrigatório"),
  });

  const handleSubmit = async (values: IAuthLoginDTO) => {
    try {
      const urlString = window.location.href;
      const paramString = urlString.split("?")[1];
      const queryString = new URLSearchParams(paramString);
      const rules = queryString.get("rules");
      const url = queryString.get("url");
      const redirectURL = new_authentication_flow
        ? generateUrlToRedirect("hub", Port.DASH_SYSTEMS_PORT)
        : url;
      const hasParams = urlString.indexOf("?") === -1;
      let isAuthorized;

      if (!new_authentication_flow) {
        if (hasParams) {
          throw new Error("Ausência de Parametros na Url");
        }

        if (!url) {
          throw new Error(
            "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
          );
        }

        if (!rules) {
          throw new Error(
            "Ausencia de Parametros na Url - regras do projeto (rules off) "
          );
        }
      }
      const loginResponse = await authService.authLogin(values);

      switch (loginResponse.state) {
        case AuthState.EXHAUSTED_ATTEMPTS: {
          throw new Error("Muitas tentativas de acesso");
        }
        case AuthState.INCORRECT_TOKEN: {
          throw new Error("Código informado, incorreto");
        }
        case AuthState.MUST_START_CHALLENGE: {
          navigate(routesConfig.LOGIN);
          throw new Error("Muitas tentativas de acesso");
        }
        case AuthState.TOO_MANY_ACCESSES: {
          navigate(routesConfig.LOGIN);
          throw new Error("Muitas tentativas de acesso");
        }
      }
      isAuthorized = new_authentication_flow
        ? loginResponse?.AccessToken && loginResponse?.RefreshToken
        : loginResponse?.meta?.roles?.some((role) =>
            ROLES[rules]?.includes(role)
          ) || false;

      if (isAuthorized) {
        navigateCookies({
          accessToken: loginResponse.AccessToken,
          refreshToken: loginResponse.RefreshToken,
          redirectURL,
        });
      } else if (
        !isAuthorized &&
        (loginResponse.state as AuthState) !== AuthState.MUST_START_CHALLENGE
      ) {
        enqueueSnackbar(
          getSnackbar({
            message:
              "Você não possui permissões, entre em contato com um administrador!",
            variant: "error",
          })
        );
        navigate(routesConfig.LOGIN);
      }
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message: error.message,
            variant: "error",
          })
        );
      }
    }
  };

  const handleResendCode = async () => {
    try {
      const urlString = window.location.href;
      const hasParams = urlString.indexOf("?") === -1;
      const paramString = urlString.split("?")[1];
      const queryString = new URLSearchParams(paramString);
      const url = queryString.get("url");

      if (!new_authentication_flow) {
        if (hasParams) {
          throw new Error("Ausência de Parametros na Url");
        }

        if (!url) {
          throw new Error(
            "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
          );
        }
      }

      await authService.authLogin({ email, password });

      enqueueSnackbar(
        getSnackbar({
          message: "Código enviado com sucesso",
          variant: "success",
        })
      );
    } catch (error) {
      enqueueSnackbar(
        getSnackbar({
          message: error.message,
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!location.state || !email || !password) {
      navigate(routesConfig.LOGIN);
    }
  }, [email, location.state, navigate, password]);

  return (
    <Formik
      initialValues={{ email, password, mfaToken: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <Grid
              item
              xs={12}
              md={7}
              display={{ xs: "none", md: "flex" }}
              flexDirection={"column"}
            >
              <Box sx={{ height: { xs: 0, md: "52%" } }} />
              <Box sx={{ height: { xs: 0, md: "48%" } }}>
                <Grid container spacing={3} ml={"5%"} mt={3}></Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              mt={{ xs: 6, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SecurityAuthCard
                formikProps={props}
                email={email}
                onResendCode={handleResendCode}
              />
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default SecurityAuth;
