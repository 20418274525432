import { Check } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import { animated, useSpring } from "react-spring";
import { useMedia } from "use-media";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledTextField } from "../../components/StyledTextField";
import { routesConfig } from "../../config/routes";
import { useQueryParams } from "../../hooks/ContextualNavigations";

interface IFormikProps {
  email: string;
  name: string;
  phone: string;
  companyName: string;
}

interface RequestAccessCardProps {
  formikProps: FormikProps<IFormikProps>;
}

const RequestAccessCard: React.FC<RequestAccessCardProps> = ({
  formikProps,
}) => {
  const isMobile = useMedia({ maxWidth: 900 });
  const [props, set] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, -60%, 0)",
  }));

  const { query } = useQueryParams();

  useEffect(() => {
    set({
      opacity: 1,
      transform: "translate3d(0, 0%, 0)",
    });
  }, [set]);

  const CustomPhoneInput = React.forwardRef((props, ref) => {
    return (
      <StyledTextField
        {...props}
        type={"text"}
        inputRef={ref}
        inputProps={{
          maxLength: 15,
        }}
      />
    );
  });

  return (
    <animated.div
      style={{
        ...props,
        width: isMobile ? "95%" : "80%",
        height: "83%",
      }}
    >
      <StyledCard
        sx={{
          height: "100%",
          borderRadius: "4rem",
          zIndex: 1,
        }}
        cardContentProps={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent={"space-between"}>
          <Box>
            <Typography fontSize={"2.1rem"}>Bem vindo!</Typography>

            <Typography fontSize="3rem" fontWeight={500}>
              Solicitar acesso
            </Typography>
          </Box>
          <Box>
            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "right",
                color: "#000",
              }}
              underline="none"
              component={LinkRouter}
              to={`${routesConfig.LOGIN}${query}`}
            >
              <span
                style={{
                  color: "#5C6F7E",
                }}
              >
                Já tem acesso?
              </span>{" "}
              Entrar
            </Link>
          </Box>
        </Box>

        <Box>
          <StyledTextField
            type="email"
            id="email"
            name="email"
            value={formikProps.values.email}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            placeholder="Username ou email address"
            labelprops={{
              label: "Informe seu email",
              hasTouched: formikProps.touched.email,
              hasErros: formikProps.errors.email,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {formikProps.touched.email && !formikProps.errors.email && (
                      <Check sx={{ color: "#0BB873", opacity: "1" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            rootProps={{
              marginTop: "6rem",
            }}
          />

          <Box display={"flex"}>
            <StyledTextField
              type={"text"}
              id="name"
              name="name"
              value={formikProps.values.name}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              fullWidth
              labelprops={{
                label: "Nome",
                hasTouched: formikProps.touched.name,
                hasErros: formikProps.errors.name,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      {formikProps.touched.name && !formikProps.errors.name && (
                        <Check sx={{ color: "#0BB873", opacity: "1" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              rootProps={{
                marginTop: "3.4rem",
              }}
            />
            <PhoneInput
              id="phone"
              name="phone"
              country="BR"
              value={formikProps.values.phone}
              onChange={(phone) => {
                console.log(phone);
                formikProps.handleChange(phone ? phone : "");
              }}
              onBlur={formikProps.handleBlur}
              inputComponent={CustomPhoneInput}
              fullWidth
              labelprops={{
                label: "Telefone com DDD",
                hasTouched: formikProps.touched.phone,
                hasErros: formikProps.errors.phone,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      {formikProps.touched.phone &&
                        !formikProps.errors.phone && (
                          <Check sx={{ color: "#0BB873", opacity: "1" }} />
                        )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              rootProps={{
                marginTop: "3.4rem",
              }}
            />
          </Box>
          <StyledTextField
            type={"text"}
            id="companyName"
            name="companyName"
            value={formikProps.values.companyName}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            labelprops={{
              label: "Nome da empresa",
              hasTouched: formikProps.touched.companyName,
              hasErros: formikProps.errors.companyName,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {formikProps.touched.companyName &&
                      !formikProps.errors.companyName && (
                        <Check sx={{ color: "#0BB873", opacity: "1" }} />
                      )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            rootProps={{
              marginTop: "3.4rem",
            }}
          />
        </Box>
        <Box flex={1} />
        <Box mb={3}>
          <StyledButton
            fullWidth
            variant="contained"
            type="submit"
            loading={formikProps.isSubmitting}
          >
            Entrar
          </StyledButton>
        </Box>
      </StyledCard>
    </animated.div>
  );
};

export default RequestAccessCard;
