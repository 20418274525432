import { Check } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import AnimatedCard from "../../components/AnimatedCard";
import { NoVisibleIcon, VisibleIcon } from "../../components/Icons";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledTextField } from "../../components/StyledTextField";
import { routesConfig } from "../../config/routes";
import { IAuthLoginDTO } from "../../modules/user/models/IAuthService";
import { useQueryParams } from "../../hooks/ContextualNavigations";
import { useFlags } from "flagsmith/react";

interface LoginCardProps {
  formikProps: FormikProps<IAuthLoginDTO>;
}

const LoginCard: React.FC<LoginCardProps> = ({ formikProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { query } = useQueryParams();

  const flags = useFlags(["new_authentication_flow"]);
  const new_authentication_flow = flags.new_authentication_flow.enabled;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const linkTo = (): string => {
    return new_authentication_flow
      ? routesConfig.PASSWORD_RECOVERY
      : `${routesConfig.PASSWORD_RECOVERY}${query}`;
  };

  return (
    <AnimatedCard>
      <StyledCard
        sx={{
          height: "100%",
          borderRadius: "4rem",
          zIndex: 1,
        }}
        cardContentProps={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent={"space-between"}>
          <Box>
            <Typography fontSize={"2.1rem"}>Bem vindo!</Typography>

            <Typography fontSize="5rem" fontWeight={500}>
              Entrar
            </Typography>
          </Box>
          <Box>
            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "right",
                color: "#000",
              }}
              underline="none"
              href="https://ream.com.br/contatos/"
              target={"_blank"}
            >
              <span
                style={{
                  color: "#5C6F7E",
                }}
              >
                Não tem acesso?
              </span>
              Solicite aqui
            </Link>
          </Box>
        </Box>

        <Box>
          <StyledTextField
            type="email"
            id="email"
            name="email"
            value={formikProps.values.email}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            placeholder="Nome do usuário ou email de acesso"
            labelprops={{
              label: "Informe seu nome de usuário ou email",
              hasTouched: formikProps.touched.email,
              hasErros: formikProps.errors.email,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {formikProps.touched.email && !formikProps.errors.email && (
                      <Check sx={{ color: "#0BB873", opacity: "1" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            rootProps={{
              marginTop: "8.6rem",
            }}
          />

          <StyledTextField
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={formikProps.values.password}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            labelprops={{
              label: "Senha",
              hasTouched: formikProps.touched.password,
              hasErros: formikProps.errors.password,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <NoVisibleIcon sx={{ color: "white" }} />
                    ) : (
                      <VisibleIcon sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            rootProps={{
              marginTop: "3.6rem",
            }}
          />

          <Box sx={{ textAlign: "right", fontSize: "1.3rem", mt: 1 }}>
            <Link
              color="titles.main"
              underline="none"
              component={LinkRouter}
              to={linkTo()}
            >
              Esqueci a senha
            </Link>
          </Box>
        </Box>
        <Box flex={1} />
        <Box mb={3}>
          <StyledButton
            fullWidth
            variant="contained"
            type="submit"
            loading={formikProps.isSubmitting}
          >
            Entrar
          </StyledButton>
        </Box>
      </StyledCard>
    </AnimatedCard>
  );
};

export default LoginCard;
