import { Box, LinearProgress, Link, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import AnimatedCard from "../../components/AnimatedCard";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledTextField } from "../../components/StyledTextField";
import { IAuthLoginDTO } from "../../modules/user/models/IAuthService";
import { routesConfig } from "../../config/routes";
import { Link as LinkRouter } from "react-router-dom";
import { useQueryParams } from "../../hooks/ContextualNavigations";
interface SecurityAuthCardProps {
  formikProps: FormikProps<IAuthLoginDTO>;
  email: string;
  onResendCode?: () => Promise<void>;
}

const SecurityAuthCard: React.FC<SecurityAuthCardProps> = ({
  formikProps,
  email,
  onResendCode,
}) => {
  const { query } = useQueryParams();
  const [isLoadingResendCode, setIsLoadingResendCode] = useState(false);

  const handleOnChange = (event) => {
    const value = event.target.value;

    if (!/^\d{0,6}$/.test(value)) {
      event.preventDefault();
      return;
    }
    formikProps.handleChange(event);
  };

  const handleResendCode = async () => {
    setIsLoadingResendCode(true);
    await onResendCode();
    setIsLoadingResendCode(false);
  };
  return (
    <AnimatedCard>
      <StyledCard
        sx={{
          height: "100%",
          borderRadius: "4rem",
          zIndex: 1,
        }}
        cardContentProps={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent={"space-between"}>
          <Box>
            <Typography fontSize={"2.1rem"}>Autenticação de</Typography>

            <Typography fontSize="5rem" fontWeight={500}>
              Segurança
            </Typography>
          </Box>
          <Box>
            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "right",
                color: "#000",
              }}
              underline="none"
              href="https://ream.com.br/contatos/"
              target={"_blank"}
            >
              <span
                style={{
                  color: "#5C6F7E",
                }}
              >
                Não tem acesso?
              </span>{" "}
              Solicite aqui
            </Link>
          </Box>
        </Box>

        <Box mt={10}>
          <Box mb={3}>
            <Typography fontSize={"2.4rem"} color={"subTitles.main"}>
              {email}
            </Typography>
            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                color: "#404040",
                textDecorationColor: "#404040",
                width: "200px",
              }}
              component={LinkRouter}
              to={`${routesConfig.LOGIN}${query}`}
            >
              <Typography
                fontSize="1.4rem"
                fontWeight={300}
                color={"titles.main"}
              >
                Trocar de e-mail
              </Typography>
            </Link>
          </Box>
          <StyledTextField
            type="text"
            id="mfaToken"
            name="mfaToken"
            value={formikProps.values.mfaToken}
            onChange={handleOnChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            placeholder="Código"
            labelprops={{
              label: "Informe o código recebido no e-mail informado",
              hasTouched: formikProps.touched.mfaToken,
              hasErros: formikProps.errors.mfaToken,
            }}
            inputProps={{
              maxLength: 6,
              max: 999999,
              style: {
                ...(formikProps.values.mfaToken
                  ? {
                      textAlign: "center",
                      fontSize: "2.4rem",
                      letterSpacing: "4rem",
                    }
                  : {}),
              },
            }}
          />

          <Box
            sx={{
              fontSize: "1.3rem",
              mt: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {isLoadingResendCode ? (
              <Box width={"25%"}>
                <LinearProgress />
              </Box>
            ) : (
              <Link
                color="titles.main"
                underline="none"
                onClick={handleResendCode}
                sx={{
                  cursor: "pointer",
                }}
              >
                Reenviar código
              </Link>
            )}
          </Box>
        </Box>
        <Box flex={1} />
        <Box mb={3}>
          <StyledButton
            fullWidth
            variant="contained"
            type="submit"
            loading={formikProps.isSubmitting}
          >
            Acessar
          </StyledButton>
        </Box>
      </StyledCard>
    </AnimatedCard>
  );
};

export default SecurityAuthCard;
