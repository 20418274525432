import { Box, BoxProps, Grid, GridProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import useMedia from "use-media";
import { StyledIcons } from "../StyledIcons";

const SalyIconSVG: React.FC<BoxProps<"img">> = (props) => {
  return (
    <Box
      component={'img'}
      {...props}
    />
  );
}

export interface StyledLayoutProps extends GridProps<React.ElementType> { }

const StyledGridContainer = styled(
  ({ children, sx, ...props }: StyledLayoutProps) => {
    const isMobile = useMedia({ maxWidth: 810 });
    return <Grid container
      sx={{
        width: "100%",
        height: "100vh",
        background: "linear-gradient(to bottom, #F5F5F5 0%, #F5F5F5 50%, #F59D03 50%, #F59D03 52%, #E20F1A 52%, #E20F1A 100%)",
        backgroundClip: 'content-box',
      }}
      {...props}
    >
      <Box position={'absolute'} top={'5%'} left={'5%'} display={'flex'} alignItems={'flex-start'}>
        <Box>
          <StyledIcons
            iconType="logo"
            sx={{
              width: "20rem",
              height: null,
              padding: 0
            }} />

          {!isMobile && <Box>
            <Typography fontWeight={600} fontSize={'3.4rem'} mt={3} color='titles.main'>
              Canal do Cliente
            </Typography>
            <Typography fontWeight={600} fontSize={'2rem'} mt={2} color='subtitles.main'>
              Com DNA amazônico,<br />
              chegamos com muita energia!
            </Typography>
            <Typography fontWeight={30} fontSize={'1.3rem'} mt={2}>
              A Ream Participações surge para elevar a<br />
              eficiência e aumentar a produtividade da<br />
              Refinaria de Manaus.
            </Typography>
          </Box>}
        </Box>
        {!isMobile && <Box>
          <SalyIconSVG
            src="/images/icons/saly.svg" />
        </Box>}
      </Box>
      {children}
    </Grid>;
  }
)(({ theme }) => ({}));

export { StyledGridContainer };
