export interface QueryTypesNewPassword {
  email: string;
  verification_code: string;
}

export interface SecurityAuthQuery {
  email: string
  password: string
}

export const routesConfig = {
  HOME: "*",
  LOGIN: "/login",
  LOGOUT: "/logout",
  SECURITY_AUTH: "/security-auth",
  CREATE_NEW_PASSWORD: "/create-new-password",
  REQUEST_ACCESS: "/request-access",
  PASSWORD_RECOVERY: "/password-recovery",
};
