import { Box, IconButton, InputAdornment, Link, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { useMedia } from "use-media";
import AnimatedCard from "../../components/AnimatedCard";
import { NoVisibleIcon, VisibleIcon } from "../../components/Icons";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledTextField } from "../../components/StyledTextField";
import { IAuthUpdatePasswordDTO } from "../../modules/user/models/IAuthService";
import PasswordChecker from "./PasswordChecker";

interface CreateNewPasswordCardProps {
    formikProps: FormikProps<IAuthUpdatePasswordDTO>
}

const CreateNewPasswordCard: React.FC<CreateNewPasswordCardProps> = ({ formikProps }) => {
    const isMobile = useMedia({ maxWidth: 900 });

    // const { query } = useQueryParams();
    const [showPassword, setShowPassword] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [passwordDontMatch, setPasswordDontMatch] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const isValidForm = isValidPassword && (formikProps.values.new_password === formikProps.values.confirm_password);

    const checkPasswordMatch = () => {
        if (formikProps.touched.new_password && formikProps.touched.confirm_password) {
            setPasswordDontMatch(formikProps.values.new_password !== formikProps.values.confirm_password)
        }
    }

    return (
        <AnimatedCard>
            <StyledCard
                sx={{
                    height: '100%',
                    borderRadius: '4rem',
                    zIndex: 1,
                    ...(isMobile ? {
                        overflow: 'scroll'
                    } : {})
                }}
                cardContentProps={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >

                <Box display='flex' justifyContent={'space-between'}>
                    <Box>
                        <Typography fontSize={'2.1rem'}>
                            Criar
                        </Typography>

                        <Typography
                            fontSize='5rem'
                            fontWeight={500}
                        >
                            Nova senha
                        </Typography>
                    </Box>
                </Box>

                <Box marginTop={{ xs: '2rem', md: '5rem' }}>
                    {passwordDontMatch && <Typography
                        fontSize='1.3rem'
                        color='primary.main'
                        mb={1.5}
                    >
                        Senhas não conferem
                    </Typography>}
                    <StyledTextField
                        type={showPassword ? "text" : "password"}
                        id="new_password"
                        name="new_password"
                        value={formikProps.values.new_password}
                        onChange={formikProps.handleChange}
                        onBlur={(e) => {
                            formikProps.handleBlur(e);
                            checkPasswordMatch();
                        }}
                        fullWidth
                        placeholder="Senha"
                        labelprops={{ label: "Informe nova senha", hasTouched: formikProps.touched.new_password, hasErros: formikProps.errors.new_password }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? (
                                            <NoVisibleIcon sx={{ color: "white" }} />
                                        ) : (
                                            <VisibleIcon sx={{ color: "white" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <PasswordChecker
                        value={formikProps.values.new_password}
                        onChange={(isValid) => setIsValidPassword(isValid)}
                    />

                    <StyledTextField
                        type={showPassword ? "text" : "password"}
                        id="confirm_password"
                        name="confirm_password"
                        value={formikProps.values.confirm_password}
                        onChange={formikProps.handleChange}
                        onBlur={(e) => {
                            formikProps.handleBlur(e);
                            checkPasswordMatch()
                        }}
                        fullWidth
                        placeholder="Senha"
                        labelprops={{ label: "Confirme a nova senha", hasTouched: formikProps.touched.confirm_password, hasErros: formikProps.errors.confirm_password }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? (
                                            <NoVisibleIcon sx={{ color: "white" }} />
                                        ) : (
                                            <VisibleIcon sx={{ color: "white" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        rootProps={{
                            marginTop: '3.6rem'
                        }}
                    />

                    <Box sx={{ textAlign: "right", fontSize: "1.3rem", mt: 1 }}>
                        <Link
                            color="titles.main"
                            underline="none"
                            component={LinkRouter}
                            to={``}
                        >
                            Esqueci a senha
                        </Link>
                    </Box>

                </Box>
                <Box flex={1} />
                <Box mb={3}>
                    <StyledButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={formikProps.isSubmitting}
                        disabled={!isValidForm}
                    >
                        Entrar
                    </StyledButton>
                </Box>

            </StyledCard >
        </AnimatedCard >
    );
}

export default CreateNewPasswordCard;
