import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import CreateNewPassword from "../../pages/CreateNewPassword";
import Login from "../../pages/Login";
import Logout from "../../pages/Logout";
import PasswordRecovery from "../../pages/PasswordRecovery";
import RequestAccess from "../../pages/RequestAccess";
import SecurityAuth from "../../pages/SecurityAuth";

export const RoutesComponent: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routesConfig.HOME} element={<Logout/>} />
        <Route path={routesConfig.LOGIN} element={<Login />} />
        <Route path={routesConfig.LOGOUT} element={<Logout />} />
        <Route path={routesConfig.SECURITY_AUTH} element={<SecurityAuth />} />
        <Route
          path={routesConfig.CREATE_NEW_PASSWORD}
          element={<CreateNewPassword />}
        />
        <Route path={routesConfig.REQUEST_ACCESS} element={<RequestAccess />} />
        <Route
          path={routesConfig.PASSWORD_RECOVERY}
          element={<PasswordRecovery />}
        />
      </Routes>
    </BrowserRouter>
  );
};
