const commonRoles = ["admin", "vendor"];

const ROLES = {
  orion: [...commonRoles, "external_operator"],
  canal_cliente_ream: [...commonRoles, "customer"],
  "modify-checklist": [...commonRoles, "modify-checklist"],
  checklist: [...commonRoles, "checklist"],
  nexus: ["admin", "nexus"],
};

export default ROLES;
