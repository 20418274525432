// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { inject, injectable } from "inversify";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../http/models/IHttpService";
import { IAuthChangePasswordDTO, IAuthLoginResponse, IAuthForgetPasswordDTO, IAuthLoginDTO, IAuthService, IAuthUpdatePasswordDTO, RegisterUserService } from "../models/IAuthService";

@injectable()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class AuthService implements IAuthService {
  @inject(Types.IHttpService)
  private httpInstance!: IHttpService;

  public async authLogin(data: IAuthLoginDTO): Promise<IAuthLoginResponse> {
    return await this.httpInstance.post("/auth/login", data);
  }

  public async updatePassword(data: IAuthUpdatePasswordDTO): Promise<IAuthLoginResponse> {
    return this.httpInstance.post(`/auth/update-password`, data);
  }

  public async forgetPassword(data: IAuthForgetPasswordDTO): Promise<void> {
    return this.httpInstance.post(`/auth/forget-password`, data);
  }

  public async changePassword(data: IAuthChangePasswordDTO): Promise<void> {
    return this.httpInstance.post(`/auth/change-password`, {
      email: data.email,
      password: data.password,
      confirmation_code: data.confirmation_code,
    });
  }

  public async register(data: RegisterUserService): Promise<void> {
    return this.httpInstance.post(`/admin/auth/create_account_request`, {
      name: data.name,
      email: data.email,
      password: data.password,
      role: data.role,
    });
  }
}
