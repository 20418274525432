import { Box, BoxProps, StandardTextFieldProps, TextField, Typography } from "@mui/material";
import { alpha, styled, TypographyProps } from "@mui/system";

export interface StyledTextFieldProps extends StandardTextFieldProps {
  labelprops?: { label: string, hasTouched?: boolean, hasErros?: string, } & TypographyProps;
  rootProps?: BoxProps<'div'>
}

const StyledTextField = styled((props: StyledTextFieldProps) => {
  const { labelprops, rootProps, ...textfieldProps } = props;
  const { label, hasTouched, hasErros, ...restLabelProps } = labelprops;
  return (
    <Box {...rootProps}>
      {labelprops?.label && (
        <Typography
          {...{
            fontSize: { xs: "1.6rem" },
            fontWeight: 400,
            mb: { xs: 1 },
          }}
          {...restLabelProps}
        >
          {labelprops?.label}
        </Typography>
      )}

      <TextField
        id="outlined-basic"
        variant="outlined"
        sx={{
          '& fieldset': {
            borderRadius: "0.9rem",
          },
          '& .MuiOutlinedInput-root': {
            "& fieldset": {
              color: "red",
              border: props.labelprops.hasTouched && props.labelprops.hasErros ? "1px solid #F97A91" : "1px solid gainsboro",
            },
            '&:hover fieldset': {
              borderColor: '#4285F4',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#4285F4',
              color: 'green',
              fontWeight: 700,
            },
          },
        }} {...textfieldProps} />

      {labelprops.hasTouched && labelprops.hasErros && (
        <Typography color="tagsAndStatus.2" sx={{ fontSize: "1.2rem", marginLeft: "3rem", textAlign: "left", mt: 0.5, ml: 0 }}>
          {labelprops.hasErros}
        </Typography>
      )}
    </Box>
  );
})(({ theme }) => ({
  "& .MuiInput-underline:before": {
    borderBottomColor: alpha(theme.palette.subTitles.main, 0.3),
  },
  "& .MuiInput-input": {
    color: theme.palette.subTitles.main,
  },
}));

export { StyledTextField };
