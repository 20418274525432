import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { StyledGridContainer } from "../../components/StyledLayout";
import { routesConfig } from "../../config/routes";
import { useIocContext } from "../../contexts/ioc/IocContext";
import {
  useContextualNavigate,
  useNavigationCookies,
} from "../../hooks/ContextualNavigations";
import { Types } from "../../ioc/types";
import {
  AuthState,
  IAuthLoginDTO,
  IAuthLoginResponse,
  IAuthService,
} from "../../modules/user/models/IAuthService";
import { getSnackbar } from "../../utils/Snackbars";
import AppError from "../../utils/appError";
import LoginCard from "./LoginCard";
import { generateUrlToRedirect } from "../../utils";
import ROLES from "../../config/roles";
import { useFlags } from "flagsmith/react";
import { Port } from "./localPort";

export const Login: React.FC = () => {
  let navigate = useContextualNavigate();
  let navigateCookies = useNavigationCookies();

  const flags = useFlags(["new_authentication_flow"]);
  const new_authentication_flow = flags.new_authentication_flow.enabled;

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();

  const urlString = window.location.href;
  const paramString = urlString.split("?")[1];
  const queryString = new URLSearchParams(paramString);
  const rules = queryString.get("rules");
  const url = queryString.get("url");
  const rbac = queryString.get("rbac");
  const hasParams = urlString.indexOf("?") === -1;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
    password: yup.string().required("Este campo é obrigatório"),
  });

  const handleBypassedMFA = async (loginResponse: IAuthLoginResponse) => {
    try {
      let isAuthorized;

      const redirectURL =
        new_authentication_flow && rbac === "true"
          ? generateUrlToRedirect("rbac-editor", Port.RBAC_EDITOR)
          : new_authentication_flow && !rbac
          ? generateUrlToRedirect("hub", Port.DASH_SYSTEMS_PORT)
          : url;

      switch (loginResponse.state) {
        case AuthState.EXHAUSTED_ATTEMPTS: {
          throw new Error("Muitas tentativas de acesso");
        }
        case AuthState.INCORRECT_TOKEN: {
          throw new Error("Código informado, incorreto");
        }
        case AuthState.MUST_START_CHALLENGE: {
          navigate(routesConfig.LOGIN);
          throw new Error("Muitas tentativas de acesso");
        }
        case AuthState.TOO_MANY_ACCESSES: {
          navigate(routesConfig.LOGIN);
          throw new Error("Muitas tentativas de acesso");
        }
      }

      new_authentication_flow
        ? (isAuthorized =
            loginResponse?.AccessToken && loginResponse?.RefreshToken)
        : (isAuthorized =
            loginResponse?.meta?.roles?.some((role) =>
              ROLES[rules]?.includes(role)
            ) || false);

      if (isAuthorized) {
        navigateCookies({
          accessToken: loginResponse.AccessToken,
          refreshToken: loginResponse.RefreshToken,
          redirectURL,
        });
      } else if (
        !isAuthorized &&
        (loginResponse.state as AuthState) !== AuthState.MUST_START_CHALLENGE
      ) {
        enqueueSnackbar(
          getSnackbar({
            message:
              "Você não possui permissões, entre em contato com um administrador!",
            variant: "error",
          })
        );
        navigate(routesConfig.LOGIN);
      }
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message: error.message,
            variant: "error",
          })
        );
      }
    }
  };

  const handleSubmit = async (values: IAuthLoginDTO) => {
    try {
      const authResponse = await authService.authLogin(values);

      const isAdminToRbac =
        new_authentication_flow &&
        rbac === "true" &&
        !authResponse?.meta?.roles.includes("admin");

      const isNewFlowWithRbac =
        !new_authentication_flow ||
        (new_authentication_flow && !rbac) ||
        (new_authentication_flow && rbac === "true");

      if (!new_authentication_flow) {
        if (hasParams) {
          throw new Error("Ausência de Parametros na Url");
        }

        if (!url) {
          throw new Error(
            "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
          );
        }

        if (!rules) {
          throw new Error(
            "Ausencia de Parametros na Url - regras do projeto (rules off) "
          );
        }
      }

      if (isAdminToRbac) {
        throw new Error("Você não possui permissão de admin");
      }

      if (isNewFlowWithRbac) {
        switch (authResponse.state) {
          case AuthState.SENT: {
            return navigate(routesConfig.SECURITY_AUTH, {
              state: {
                email: values.email,
                password: values.password,
              },
            });
          }
          case AuthState.FAILED_SENT: {
            throw new Error(
              "Tivemos um problema ao enviar o link de verificação para seu email"
            );
          }
          case AuthState.FIRST_ACCESS: {
            return navigate(routesConfig.CREATE_NEW_PASSWORD, {
              state: {
                email: values.email,
                old_password: values.password,
              },
            });
          }
          case AuthState.TOO_MANY_ACCESSES: {
            throw new Error("Muitas tentativas de acesso");
          }
          default:
            return handleBypassedMFA(authResponse);
        }
      }

      if (new_authentication_flow && rbac !== "true") {
        throw new Error("Ops, algo errado aconteceu...");
      }
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message: error.message,
            variant: "error",
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "", mfaToken: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <Grid
              item
              xs={12}
              md={7}
              display={{ xs: "none", md: "flex" }}
              flexDirection={"column"}
            >
              <Box sx={{ height: { xs: 0, md: "52%" } }} />
              <Box sx={{ height: { xs: 0, md: "48%" } }}>
                <Grid container spacing={3} ml={"5%"} mt={3}></Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              mt={{ xs: 6, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoginCard formikProps={props} />
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
