import { Card, CardContent, CardProps } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/system";

export interface StyledCardProps extends CardProps<React.ElementType> {
  cardContentProps?: SxProps<Theme>
}

const StyledCard = styled(
  ({ children, sx, cardContentProps, ...props }: StyledCardProps) =>
    <Card
      sx={{
        boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
        background: "white",
        border: "2px solid white",
        borderRadius: "2rem",
        padding: "1rem",
        ...sx,
      }}

      {...props}
    >
      <CardContent
        sx={{
          my: 1,
          mx: 1,
          marginBottom: 6,
          "&:last-child": {
            paddingBottom: 0
          },
          ...cardContentProps,
        }}>
        {children}
      </CardContent>

    </Card >
)(({ theme }) => ({}));

export { StyledCard };
