import { Check } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Link, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import AnimatedCard from "../../components/AnimatedCard";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledTextField } from "../../components/StyledTextField";
import { IAuthForgetPasswordDTO } from "../../modules/user/models/IAuthService";

interface PasswordRecoveryCardProps {
    formikProps: FormikProps<IAuthForgetPasswordDTO>
}

const PasswordRecoveryCard: React.FC<PasswordRecoveryCardProps> = ({ formikProps }) => {

    return (
        <AnimatedCard>
            <StyledCard
                sx={{
                    height: '100%',
                    borderRadius: '4rem',
                    zIndex: 1,
                }}
                cardContentProps={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box display='flex' justifyContent={'space-between'}>
                    <Box>
                        <Typography fontSize={'2.1rem'}>
                            Recuperação de
                        </Typography>

                        <Typography
                            fontSize='5rem'
                            fontWeight={500}
                        >
                            Senha
                        </Typography>
                    </Box>
                    <Box>
                        <Link
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'right',
                                color: '#000'
                            }}
                            underline="none"
                            href="https://ream.com.br/contatos/"
                            target={'_blank'}
                        >
                            <span
                                style={{
                                    color: "#5C6F7E"
                                }}
                            >
                                Não tem acesso?
                            </span>{" "}
                            Solicite aqui
                        </Link>
                    </Box>
                </Box>

                <Box>

                    <StyledTextField
                        type="email"
                        id="email"
                        name="email"
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        fullWidth
                        placeholder="email"
                        labelprops={{ label: "Informe o email cadastrado para recuperar a senha", hasTouched: formikProps.touched.email, hasErros: formikProps.errors.email }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" >
                                        {formikProps.touched.email && !formikProps.errors.email && <Check sx={{ color: "#0BB873", opacity: "1" }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        rootProps={{
                            marginTop: '8.6rem'
                        }}
                    />
                </Box>
                <Box flex={1} />
                <Box mb={3}>
                    <StyledButton
                        fullWidth
                        variant="contained"
                        type="submit"
                        loading={formikProps.isSubmitting}
                        disabled={!formikProps.isValid}
                    >
                        Recuperar senha
                    </StyledButton>
                </Box>

            </StyledCard >
        </AnimatedCard>
    );
}

export default PasswordRecoveryCard;
